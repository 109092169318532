<template>
  <article class="expedientes">
    <header>
      <h3>Expedientes > {{ addTitle }}</h3>

      <article>
        <div>
          <div v-if="isAdmin || isAuditor">
            <v-select v-model="bodegaId" :items="bodegas" item-text="nombreBodega" item-value="codigoBodega"
              label="Seleccione Bodega" outlined dense hide-details @change="handleStatusChange"
              :menu-props="{ offsetY: true, maxHeight: 'none', eager: true }" />
          </div>
          <div v-if="isAdmin || isAuditor">

            <v-select v-model="idContrato" :items="contratos" item-text="nombreContrato" item-value="idContrato"
              label="Seleccione Contrato" outlined dense hide-details @change="handleStatusChange"
              :menu-props="{ offsetY: true }" />
          </div>
          <div>
            <v-select v-model="estado" :items="statusOptions2" label="Seleccione Estado" outlined dense hide-details
              @change="handleStatusChange" :menu-props="{ offsetY: true, maxHeight: 'none', eager: true }"></v-select>
          </div>
          <div>
            <v-text-field class="me-2 filtro" v-model="codigoInterno" :counter="10" label="Código Interno" hide-details
              @input="handleStatusChange" outlined dense></v-text-field>
          </div>
          <div>

            <v-text-field class="me-2 filtro" v-model="numeroDocumento" :counter="10" label="Afiliado" hide-details
              @input="handleStatusChange" outlined dense></v-text-field>
          </div>
        </div>
        <div>
          <template>
            <v-container>
              <v-row>
                <!-- Select de filtros de fecha -->
                <v-col cols="3">
                  <v-select v-model="filtroSeleccionado" :items="filtros" label="Selecciona un filtro de fecha" outlined
                    dense @change="aplicarFiltro"
                    :menu-props="{ offsetY: true, maxHeight: 'none', eager: true }"></v-select>
                </v-col>

                <!-- Campo de fecha y calendario -->
                <v-col cols="3">
                  <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition"
                    :value="filtroSeleccionado === 'Personalizado'">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="fechaMostrada" label="Rango de fechas" readonly outlined dense
                        v-bind="attrs" v-on="on"></v-text-field>
                    </template>

                    <!-- 📅 Calendario con selección de rango de fechas -->
                    <v-date-picker v-model="fechaSeleccionada" range no-title @input="actualizarFechas"></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="6">
                  <template>

                    <!-- Select con checkbox -->
                    <v-select v-model="selectedFilters" :items="filterOptions" label="Soportes Pendientes" outlined
                      dense multiple hide-details :menu-props="{ offsetY: true, maxHeight: 'none', eager: true }">
                      <template v-slot:prepend-item>
                        <v-list-item ripple @click="toggleAll">
                          <v-list-item-action>
                            <v-checkbox :input-value="selectAll" color="primary"></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>Seleccionar Todos</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                      </template>

                      <template v-slot:item="{ item }">
                        <v-list-item ripple @click.stop="toggleItem(item.value)">
                          <v-list-item-action>
                            <v-checkbox :input-value="selectedFilters.includes(item.value)"
                              color="primary"></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>{{ item.text }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>

                    <!-- Chips debajo del select -->
                    <div v-if="selectedFilters.length" class="mt-2 d-flex flex-wrap">
                      <v-chip v-for="(filter, index) in selectedFilters" :key="index" close
                        @click:close="removeFilter(filter)" class="mr-2">
                        {{ getFilterText(filter) }}
                      </v-chip>
                    </div>

                  </template>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </div>
      </article>
      <section>
        <v-btn v-if="isAnySelected" variant="elevated" color="primary" @click="cambiarEstado()">
          Cambiar estado
        </v-btn>
        <v-btn v-if="isAnySelected && (addTitle == 'Radicador' || addTitle == 'Administrador')" variant="elevated"
          color="primary" @click="descargarExpedientes(expediente)">
          Descargar
        </v-btn>
        <!-- <v-btn variant="elevated" color="secundary" @click="crearExpediente">
          Crear Expediente
        </v-btn> -->
        <v-btn variant="elevated" color="primary" @click="cambiarVista">
          <v-icon class="me-2">change_circle</v-icon>
        </v-btn>
      </section>
    </header>
    <section v-if="vista">
      <ul>
        <li v-for="(expediente, index) in expedientes" :key="index" class="completa">
          <div class="expediente-folder">
            <v-img src="@/assets/images/folder.png"></v-img>
          </div>
          <header @click="redirectToExpediente(expediente.expediente)">
            <article>
              <div class="expediente-id">{{ expediente.expediente.idExpediente }}</div>
              <!-- <h3>Completa</h3> -->
              <h4>{{ expediente.expediente.createDate.substring(0, expediente.expediente?.createDate.indexOf("T")) }} {{
                expediente.expediente.createDate.substring(expediente.expediente?.createDate.indexOf("T") + 1,
                  expediente.expediente?.createDate.indexOf("."))
              }}</h4>
              <h5><span>Código interno:{{ expediente.expediente.codigoInterno }}</span></h5>
            </article>
          </header>
          <div @click="redirectToExpediente(expediente.expediente)">
            <ol>
              <li>
                <figure><v-icon class="me-2">person</v-icon></figure>
                <h5>{{ expediente.expediente.afiliado.nombreCompleto }}</h5>
              </li>
              <li>
                <figure><v-icon class="me-2">badge</v-icon></figure>
                <h5>{{ expediente.expediente.afiliado.numeroDocumento }}</h5>
              </li>
              <li>
                <figure><v-icon class="me-2">bookmark</v-icon></figure>
                <h5>{{ expediente.expediente.estado }}</h5>
              </li>
            </ol>
          </div>
          <footer>
            <div>
              <!-- <figure @click="redirectToExpediente(expediente.expediente)"><v-icon class="me-2">visibility</v-icon></figure> -->
              <figure v-if="puedeCambiarEstado" variant="elevated" color="primary" @click="cambiarEstado()">
                Cambiar estado><v-icon class="me-2">change_circle</v-icon></figure>
              <figure v-if="addTitle == 'Radicador' || addTitle == 'Administrador'">
                <v-icon class="me-2" @click="descargarExpediente(expediente.expediente)"
                  title="Descargar">download</v-icon>
              </figure>
              <figure
                v-if="addTitle != 'Caja' && (addTitle != 'Auditor' || expediente.expediente.estado == 'COMPLETO')">
                <v-checkbox v-model="expediente.expediente.seleccionado" :value="expediente.expediente.seleccionado"
                  color="primary" hide-details />
              </figure>
            </div>
          </footer>
        </li>
      </ul>
      <v-divider></v-divider>

      <div class="custom-pagination" v-if="expedientes.length">
        <v-btn icon @click="goToPage(1)" :disabled="page === 1">
          <v-icon>first_page</v-icon>
        </v-btn>
        <v-btn icon @click="prevPage" :disabled="page === 1">
          <v-icon>chevron_left</v-icon>
        </v-btn>

        <span class="page-info">Página {{ page }} de {{ totalPages }}</span>

        <v-btn icon @click="nextPage" :disabled="page >= totalPages">
          <v-icon>chevron_right</v-icon>
        </v-btn>
        <v-btn icon @click="goToPage(totalPages)" :disabled="page >= totalPages">
          <v-icon>last_page</v-icon>
        </v-btn>

        <!-- Selector de elementos por página -->
        <v-select v-model="itemsPerPage" :items="[5, 10, 20, 50]" label="Por página" dense outlined
          class="pagination-select" @change="getExpedientes" :menu-props="{ offsetY: true }"></v-select>
      </div>
    </section>
    <section v-if="loading">
      <div class="loading">
        <div class="loadingio-spinner-spinner-2by998twmg8">
          <div class="ldio-yzaezf3dcmj">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <h4>{{ loadingText }}</h4>
      </div>
    </section>
    <section v-if="!vista">
      <v-container>
          <v-data-table :headers="headers" :items="expedientes" :items-per-page.sync="itemsPerPage" :page.sync="page"
            :server-items-length="totalItems" :items-per-page-options="[5, 10, 15, 50, 100]"
            class="elevation-1 custom-table" item-value="idExpediente" must-sort
            no-data-text="No hay expedientes disponibles" @update:items-per-page="getExpedientes"
            @update:page="getExpedientes" :footer-props="{ 'items-per-page-text': 'Filas por página' }">

          <template v-slot:body>
            <tr v-for="(item, index) in expedientes" :key="index" class="hover-row bordered-row">
              <td>
                <template>
                  <div class="d-flex align-center">
                    <v-checkbox v-if="addTitle !== 'Caja' && (addTitle == 'Radicador')"
                      v-model="item.expediente.seleccionado" color="primary" hide-details class="custom-checkbox" />
                    <div class="folder-container">
                      <v-img :src="require('@/assets/images/folder.png')" max-width="30" class="folder-icon" />
                      <span class="folder-id">{{ item.expediente.idExpediente }}</span>
                    </div>
                  </div>
                </template>

              </td>
              <td @click="redirectToExpediente(item.expediente)">
                <v-tooltip bottom color="black" dark>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">{{ formatFechaCorta(item.expediente.createDate) }}</span>
                  </template>
                  <span>{{ formatFechaCompleta(item.expediente.createDate) }}</span>
                </v-tooltip>
              </td>
              <td @click="redirectToExpediente(item.expediente)">
                <v-tooltip bottom color="#E3F2FD">
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      {{ item.expediente?.bodegas?.bodegasId.codigoBodega }}
                    </span>
                  </template>
                  <span class="font-weight-bold" style="color: #1565C0;">
                    {{ item.expediente?.bodegas?.nombreBodega }}
                  </span>
                </v-tooltip>
              </td>
              <td @click="redirectToExpediente(item.expediente)">
                {{ item.expediente?.afiliado?.numeroDocumento }}
              </td>
              <td @click="redirectToExpediente(item.expediente)">
                {{ item.expediente?.afiliado?.nombreCompleto }}
              </td>

              <td @click="redirectToExpediente(item.expediente)">
                {{ item.expediente?.codigoInterno || 'No disponible' }}
              </td>
              <td>
                {{ item.expediente.estado }}
              </td>
              <td @click="redirectToExpediente(item.expediente)">
                <template>
                  <!--<div class="faltantes-box">
                    <strong>{{ mapearDocumentosFaltantes(item.documentosFaltantes).join(' - ') }}</strong>
                  </div>
                  -->
                  <v-tooltip bottom color="#E3F2FD">
                    <template v-slot:activator="{ on }">
                      <div class="d-flex align-center" v-on="on">
                        <span v-for="n in mapearDocumentosFaltantes(item.documentosFaltantes).length" :key="n">
                          <v-icon color="red" class="mr-1">{{ icons.pdf }}</v-icon>
                        </span>
                      </div>
                    </template>
                    <div class="text-left pa-3" style="background-color: #E3F2FD; border-radius: 5px; color: #1565C0;">
                      <div v-for="(archivo, index) in mapearDocumentosFaltantes(item.documentosFaltantes)" :key="index"
                        class="d-flex align-center mb-1">
                        <v-icon color="red" class="mr-2">{{ icons.pdf }}</v-icon>
                        <span>{{ archivo }}</span>
                      </div>
                    </div>
                  </v-tooltip>

                </template>
              </td>
              <td>
                <template>
                  <div class="action-container">
                    <v-checkbox v-if="(addTitle === 'Regente' || addTitle === 'Administrador')
                      || (addTitle === 'Auditor' && item.expediente.estado === 'COMPLETO')
                      || (addTitle === 'Radicador' && item.expediente.estado === 'AUDITADO')"
                      v-model="item.expediente.seleccionado" color="primary" hide-details class="custom-checkbox" />

                    <v-tooltip bottom color="black" dark>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-if="(addTitle === 'Regente' || addTitle === 'Administrador')
                          || (addTitle === 'Auditor' && item.expediente.estado === 'COMPLETO')
                          || (addTitle === 'Radicador' && item.expediente.estado === 'AUDITADO')" color="blue" icon
                          v-bind="attrs" v-on="on" @click="cambiarEstado(item.expediente)">
                          <v-icon>{{ icons.changeStatus }}</v-icon>
                        </v-btn>

                      </template>
                      <span>Cambiar estado</span>
                    </v-tooltip>

                    <v-tooltip bottom color="black" dark>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-if="addTitle == 'Radicador' || addTitle == 'Administrador' || addTitle == 'Regente'"
                          color="black" dark icon v-bind="attrs" v-on="on"
                          @click="descargarExpediente(item.expediente)">
                          <v-icon>{{ icons.download }}</v-icon>
                        </v-btn>
                      </template>
                      <span>Descargar expediente</span>
                    </v-tooltip>

                  </div>
                </template>
              </td>
            </tr>
          </template>

          <template v-slot:no-data>
            <v-alert type="info" color="blue lighten-4" class="text-center">
              No hay expedientes disponibles.
            </v-alert>
          </template>
        </v-data-table>
      </v-container>
    </section>
    <v-dialog v-model="dialog" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline">Cambiar Estado del Expediente</v-card-title>

        <v-card-text>
          <v-select v-model="selectedStatus" :items="statusOptions" label="Seleccione Estado" outlined
            :menu-props="{ offsetY: true }"></v-select>

          <v-select v-if="selectedStatus === 'DEVUELTO' || selectedStatus === 'NOTACREDITO'"
            v-model="selectedMotivoDevolucion" :items="motivoDevolucion" item-text="descripcionMotivo"
            item-value="idMotivoDevolucion" label="Seleccione motivo devolución" outlined
            :menu-props="{ offsetY: true }">
          </v-select>



          <v-textarea v-if="selectedStatus === 'DEVUELTO' || selectedStatus === 'NOTACREDITO'" v-model="observation"
            label="Observación" outlined rows="3"></v-textarea>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn variant="elevated" color="primary" @click="saveStatus">Guardar</v-btn>
          <v-btn variant="elevated" color="primary" text @click="dialog = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </article>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import moment from 'moment';
import Swal from 'sweetalert2';
import { mdiFilePdfBox, mdiPencil, mdiDownload, mdiSync } from "@mdi/js"; // Importar íconos de MDI



export default {
  name: 'Index',
  data: () => ({
    icons: {
      pdf: mdiFilePdfBox, // Icono de PDF
      edit: mdiPencil, // Icono para editar
      download: mdiDownload, // Icono para descargar,
      changeStatus: mdiSync, // Icono para cambiar estado
    },
    filtroSeleccionado: "Hoy",
    filtros: ["Personalizado", "Hoy", "Últimos 7 días", "Últimos 15 días", "Último mes", "Últimos 3 meses", "Últimos 6 meses", "Último año", "Año pasado"],
    fechaSeleccionada: [new Date().toISOString().split("T")[0]], // Hoy por defecto
    fechaMostrada: new Date().toISOString().split("T")[0], // Mostrar hoy en el input
    motivoDevolucion: [],
    sortBy: 'expediente.idExpediente',
    sortDesc: true,
    tab: null,
    rolesKeycloak: null,
    codigosEmpresas: [],
    dataEmpresas: [],
    expedientes: [],
    usuario: "",
    estado: "TODOS",
    idContrato: "",
    bodega: "",
    afiliado: "",
    codigoInterno: "",
    contrato: "",
    fecha: "",
    fechaDisponibilidad: "",
    bodegaSeleccionada: 0,
    contratoSeleccionado: 0,
    bodegaId: "",
    nombreBodega: "",
    numeroDocumento: "",
    vista: true,
    expediente: {},
    isAdmin: false,
    isAuditor: false,
    loading: false,
    loadingText: 'Cargando expedientes',
    bodegas: [],
    contratos: [],
    empresa: 1,
    dialog: false,
    selectedStatus: null,
    selectedMotivoDevolucion: null,
    observation: '',
    statusOptions: [
      'AUDITADO',
      'COMPLETO',
      'DEVUELTO',
      'DOMICILIO',
      'INCOMPLETO',
      'NOTACREDITO',
      'RADICADO',
    ],
    statusOptions2: [
      'TODOS',
      'AUDITADO',
      'COMPLETO',
      'DEVUELTO',
      'DOMICILIO',
      'INCOMPLETO',
      'NOTACREDITO',
      'RADICADO',
    ],
    addTitle: "",

    menu: false,
    menu2: false,
    page: 1,
    size: 10,
    totalPages: 0,
    totalItems:0,
    expedientesSeleccionados: [],
    firtsLoad: false,
    currentSize: 0,
    headers: [
      { text: 'Id', value: 'expediente.idExpediente', sortable: true },
      { text: 'Fecha Expediente', value: 'expediente.createDate', sortable: true },
      { text: 'Bodega', value: 'expediente.bodegas.bodegasId.codigoBodega', sortable: true },
      { text: 'Documento', value: 'expediente.afiliado.numeroDocumento', sortable: true },
      { text: 'Afiliado', value: 'afiliado.nombreCompleto', sortable: true },
      { text: 'Código Interno', value: 'expediente.codigoInterno', sortable: true },
      { text: 'Estado', value: 'expediente.estado', sortable: true },
      { text: 'Soportes Pendientes', value: 'expediente.documentosFaltantes', sortable: false },
      { text: 'Acciones', value: 'acciones', sortable: false },
    ],
    itemsPerPage: 10,
    filterOptions: [
      { text: "Fórmula médica", value: "ATTACHMENT" },
      { text: "Factura", value: "INVOICE" },
      { text: "Hoja de Entrega", value: "HISTORY" },
      { text: "Autorización", value: "ORDER" },
      { text: "Comprobante", value: "ATTENTION" },
      { text: "Fórmula Mipres", value: "ATTACHMENTMIPRES" },
      { text: "Adres", value: "ADRES" },
      { text: "Otro Adjunto", value: "OTHER" }
    ],
    selectedFilters: [],

  }),
  mounted() {

    this.usuario = this.auth.username;
    const savedVista = localStorage.getItem("vista");
    this.vista = savedVista !== null ? JSON.parse(savedVista) : true;
    // this.empresasUsuario();

    this.empresa = this.enterprise.code;

    const vuexState = JSON.parse(localStorage.getItem('vuex'));

    if (vuexState.auth.roles.includes("Expediente_Administrador")) {
      this.isAdmin = true;
      this.addTitle = "Administrador";
      this.getBodegas();
      this.getContratos();
    }
    if (vuexState.auth.roles.includes("Expediente_Regente")) {
      this.addTitle = "Regente";
      this.statusOptions = [
        'COMPLETO',
        'DEVUELTO',
        'INCOMPLETO',
        'NOTACREDITO',
        'DOMICILIO'
      ];
      this.statusOptions2 = [
        'TODOS',
        'AUDITADO',
        'COMPLETO',
        'DEVUELTO',
        'DOMICILIO',
        'INCOMPLETO',
        'NOTACREDITO',
        'RADICADO',
      ];
    }
    if (vuexState.auth.roles.includes("Expediente_Auditor")) {
      this.addTitle = "Auditor";
      this.getBodegas();
      this.getContratos();
      this.isAuditor = true;
      this.statusOptions = [
        'AUDITADO',
        'DEVUELTO'
      ];
      this.statusOptions2 = [
        'TODOS',
        'AUDITADO',
        'COMPLETO',
        'DEVUELTO',
        'INCOMPLETO',
      ];
    }
    if (vuexState.auth.roles.includes("Expediente_Radicador")) {
      this.addTitle = "Radicador";
      this.statusOptions = [
        'RADICADO',
        'DEVUELTO'
      ];
      this.statusOptions2 = [
        'TODOS',
        'RADICADO',
        'DEVUELTO',
        'DOMICILIO'
      ];
    }
    if (vuexState.auth.roles.includes("Expediente_Caja")) {
      this.addTitle = "Caja";
      this.statusOptions = [
        'COMPLETO',
        'DEVUELTO',
        'INCOMPLETO',
        'NOTACREDITO',
        'DOMICILIO'
      ];
      this.statusOptions2 = [
        'TODOS',
        'COMPLETO',
        'DEVUELTO',
        'INCOMPLETO',
        'NOTACREDITO',
        'DOMICILIO'
      ];
    }

    if (vuexState.auth.roles.includes("Expediente_Digitalizador")) {
      this.addTitle = "Digitalizador";
      this.statusOptions2 = [
        'INCOMPLETO'
      ];

      this.estado = 'INCOMPLETO';
    }


    this.getBodega();

  },
  computed: {
    ...mapState(["auth", "enterprise"]),
    isAnySelected() {
      return this.expedientes.some(exp => exp.expediente.seleccionado);
    },
    selectAll() {
      return this.selectedFilters.length === this.filterOptions.length;
    },
    puedeCambiarEstado() {
      if (this.addTitle === 'Caja' && this.addTitle === 'Digitalizador') {
        return false; // No puede cambiar estado si el título es 'Caja'
      }

      if (this.rol === 'Administrador' || this.rol === 'Regente') {
        return true; // Puede cambiar estado en cualquier momento
      }

      if (this.rol === 'Auditor' && this.expediente.estado === 'COMPLETO') {
        return true; // Auditor solo puede cambiar si el estado es 'COMPLETO'
      }

      if (this.rol === 'Radicador' && this.expediente.estado === 'AUDITADO') {
        return true; // Radicador solo puede cambiar si el estado es 'AUDITADO'
      }

      return false; // Si no cumple ninguna condición, no puede cambiar estado
    }
  },
  watch: {
    page() {
      this.getExpedientes();
    },
    selectedStatus(newValue) {
      if (newValue === 'DEVUELTO' || newValue === 'NOTACREDITO') {
        this.getMotivoDevolucion();
      } else {
        this.selectedMotivoDevolucion = null;
        this.observation = "";
      }
    }, selectedMotivoDevolucion(newValue) {
      console.log("ID seleccionado:", newValue);
    }
  },

  methods: {
    ...mapMutations(["setEnterprise"]),
    getColor(estado) {
      switch (estado) {
        case "Activo":
          return "green";
        case "Pendiente":
          return "orange";
        case "Inactivo":
          return "red";
        default:
          return "gray";
      }
    },
    aplicarFiltro() {
      const hoy = new Date();
      let fechaInicio = "";
      let fechaFin = hoy.toISOString().split("T")[0];

      switch (this.filtroSeleccionado) {
        case "Hoy":
          fechaInicio = fechaFin;
          break;
        case "Últimos 7 días":
          fechaInicio = new Date(hoy.setDate(hoy.getDate() - 7)).toISOString().split("T")[0];
          break;
        case "Últimos 15 días":
          fechaInicio = new Date(hoy.setDate(hoy.getDate() - 15)).toISOString().split("T")[0];
          break;
        case "Último mes":
          fechaInicio = new Date(hoy.setMonth(hoy.getMonth() - 1)).toISOString().split("T")[0];
          break;
        case "Últimos 3 meses":
          fechaInicio = new Date(hoy.setMonth(hoy.getMonth() - 3)).toISOString().split("T")[0];
          break;
        case "Últimos 6 meses":
          fechaInicio = new Date(hoy.setMonth(hoy.getMonth() - 6)).toISOString().split("T")[0];
          break;
        case "Último año":
          fechaInicio = new Date(hoy.setFullYear(hoy.getFullYear() - 1)).toISOString().split("T")[0];
          break;
        case "Año pasado": {
          const anoActual = hoy.getFullYear();
          fechaInicio = `${anoActual - 1}-01-01`;
          fechaFin = `${anoActual - 1}-12-31`;
          break;
        }
        case "Personalizado":
          this.menu = true; // 📌 Abre automáticamente el calendario
          return;
      }

      // 📅 Actualizar la selección de fechas
      this.fechaSeleccionada = [fechaInicio, fechaFin];
      this.actualizarFechas();
    },
    actualizarFechas() {
      if (this.fechaSeleccionada.length === 1) {
        this.fechaMostrada = this.fechaSeleccionada[0];
      } else if (this.fechaSeleccionada.length === 2) {
        this.fechaMostrada = `${this.fechaSeleccionada[0]} - ${this.fechaSeleccionada[1]}`;
        this.menu = false;
      }
      console.log(this.fechaSeleccionada)
      this.getExpedientes();
    },
    toggleItem(value) {
      if (this.selectedFilters.includes(value)) {
        this.selectedFilters = this.selectedFilters.filter(item => item !== value);
      } else {
        this.selectedFilters.push(value);
      }
      this.getExpedientes();

    },
    toggleAll() {
      if (this.selectAll) {
        this.selectedFilters = [];
      } else {
        this.selectedFilters = this.filterOptions.map(item => item.value);
      }
      this.getExpedientes();
    },
    removeFilter(filter) {
      this.selectedFilters = this.selectedFilters.filter(item => item !== filter);
      this.getExpedientes();
    },
    getFilterText(value) {
      const option = this.filterOptions.find(item => item.value === value);
      return option ? option.text : value;
    },
    formatFechaCorta(fecha) {
      if (!fecha) return '';

      const opciones = { day: '2-digit', month: 'long' };
      const fechaObj = new Date(fecha);
      const fechaFormateada = fechaObj.toLocaleDateString('es-ES', opciones);

      const añoActual = new Date().getFullYear();
      const añoFecha = fechaObj.getFullYear();

      return añoFecha !== añoActual ? `${fechaFormateada}, ${añoFecha}` : fechaFormateada;
    },
    formatFechaCompleta(fecha) {
      if (!fecha) return '';
      const opciones = { day: '2-digit', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit' };
      return new Date(fecha).toLocaleDateString('es-ES', opciones);
    },
    getExpedientes() {
      this.loading = true;

      // Reiniciar página si cambia el tamaño de página
      if (this.currentSize !== this.itemsPerPage) {
        this.currentSize = this.itemsPerPage;
        this.page = 1;
      }

      const desde = this.fechaSeleccionada[0] ? `${this.fechaSeleccionada[0]} 00:00:00` : null;
      const hasta = this.fechaSeleccionada?.[1]
        ? `${this.fechaSeleccionada[1]} 23:59:59`
        : this.fechaSeleccionada?.[0]
          ? `${this.fechaSeleccionada[0]} 23:59:59`
          : null;

      this.$http
        .get(`/msa-process-file/api/expediente/listarExpediente`, {
          params: {
            idEmpresa: this.empresa,
            codigoBodega: this.bodegaId,
            numeroDocumento: this.numeroDocumento,
            codigoInterno: this.codigoInterno,
            estado: this.estado === "TODOS" ? "" : this.estado,
            idContrato: this.idContrato,
            tipoArchivoFaltantes: this.selectedFilters,
            desde: desde,
            hasta: hasta,
            page: this.page - 1, // Backend usa 0-index, frontend usa 1-index
            size: this.itemsPerPage,
          },
          paramsSerializer: (params) => {
            return Object.keys(params)
              .map((key) => {
                if (Array.isArray(params[key])) {
                  return params[key]
                    .map((val) => `${key}=${encodeURIComponent(val)}`)
                    .join("&");
                }
                return params[key] !== null && params[key] !== undefined
                  ? `${key}=${encodeURIComponent(params[key])}`
                  : "";
              })
              .filter(Boolean) // Eliminar parámetros vacíos
              .join("&");
          },
        })
        .then(({ data }) => {
          this.expedientes = data.content || []; // Asegurar que siempre sea un array
          this.totalItems = data.totalElements || 0;
          this.totalPages = data.totalPages || 1;
          this.loading = false;
        })
        .catch((err) => {
          console.error("Error al obtener expedientes:", err);
          this.loading = false;
          Swal.fire({
            title: "Expediente",
            text: "Hubo un error, intente nuevamente",
            icon: "error",
            confirmButtonText: "Aceptar",
          });
        });
    }
    ,
    archivosFaltantes(archivo) {
      const archivoMap = {
        "ATTACHMENT": "Fórmula médica",
        "INVOICE": "Factura",
        "HISTORY": "Hoja de Entrega",
        "ORDER": "Autorización",
        "ATTENTION": "Comprobante",
        "ATTACHMENTMIPRES": "Fórmula Mipres",
        "ADRES": "Adres",
        "OTHER": "Otro Adjunto"
      };
      return archivoMap[archivo] || archivo;
    },
    mapearDocumentosFaltantes(documentosFaltantes) {
      if (!documentosFaltantes) return [];

      if (typeof documentosFaltantes === "string") {
        return documentosFaltantes
          .split(", ") // Convertir a array
          .map(this.archivosFaltantes);
      }

      if (Array.isArray(documentosFaltantes)) {
        return documentosFaltantes.map(this.archivosFaltantes);
      }

      return [];
    },
    getChipColor(archivo) {
      const colorMap = {
        "Fórmula Médica": "blue",
        "Factura": "orange",
        "Hoja de Entrega": "teal",
        "Autorización": "purple",
        "Comprobante": "red",
        "Fórmula Mipres": "cyan",
        "Adres": "green",
        "Otro Adjunto": "gray"
      };
      return colorMap[archivo] || "gray";
    },
    formatFecha(fecha) {
      return moment(fecha).format('YYYY/MM/DD');
    },
    formatFecha2(fecha) {
      return moment(fecha).format('HH:mm');
    },
    empresasUsuario() {
      const codEmpresas = this.$http.get(`msa-setting/api/usem/relacion/${this.usuario}`)
        .then(({ data }) => this.codigosEmpresas = data)
        .catch(err => console.log(err))

      if (codEmpresas != []) {
        this.$http.post('msa-setting/api/empresa/list', this.codigosEmpresas)
          .then(({ data }) => this.dataEmpresas = data)
          .catch(err => console.log(err))
      }
    },
    toEnterprise(enterprise) {
      this.auth.roles = this.rolesKeycloak;
      this.setEnterprise(enterprise)
    },
    getBodegas() {
      this.$http.get(`msa-process-file/api/expediente/listarBodegasExpediente`)
        .then(({ data }) => {
          this.bodegas = [
            {
              codigoBodega: 0,
              nombreBodega: "TODOS"
            },
            ...data
          ];
        })
        .catch(err => console.log(err))
    },
    getBodega() {
      this.$http.get(`msa-process-file/api/file/getBodegaUsuario`)
        .then(({ data }) => {
          this.bodegaId = data.codigoBodega;
          const vuexState = JSON.parse(localStorage.getItem('vuex'));
          if (vuexState.auth.roles.includes("Expediente_Administrador") && !this.firtsLoad) {
            this.firtsLoad = true;
            this.bodegaId = 0;
          }
          this.nombreBodega = data.nombreBodega;
          this.getExpedientes();
        })
        .catch(err => console.log(err))
    },
    getContratos() {
      this.$http.get(`msa-process-file/api/expediente/listarContratosExpediente`)
        .then(({ data }) => {
          this.contratos = [
            {
              idContrato: "",
              nombreContrato: "TODOS"
            },
            ...data
          ];
        })
        .catch(err => console.log(err))
    },
    getMotivoDevolucion() {
      this.$http.get(`msa-process-file/api/motivodevolucion/listmotivodevolucion`)
        .then(({ data }) => {
          this.motivoDevolucion = data
        })
        .catch(err => console.log(err))
    },
    cambiarEstado(expediente) {
      this.dialog = true;
      this.expediente = expediente;
    },
    prevPage() {
      if (this.page > 1) {
        this.page--;
        this.getExpedientes();
      }
    },
    nextPage() {
      if (this.page < this.totalPages) {
        this.page++;
        this.getExpedientes();
      }
    },
    goToPage(p) {
      if (p >= 1 && p <= this.totalPages) {
        this.page = p;
        this.getExpedientes();
      }
    },
    descargarExpediente(expediente) {
      let codigoBodega = expediente.bodegas.bodegasId.codigoBodega;
      let idExpediente = expediente.idExpediente;

      this.loading = true;
      this.loadingText = 'Descargando expediente...';
      this.$http.get(`msa-process-file/api/expediente/descargarExpediente?idExpediente=dispensacion/${codigoBodega}/expediente/${expediente.afiliado.numeroDocumento}/${idExpediente}/`,
        {
          responseType: 'arraybuffer'
        }
      )
        .then(({ data }) => {
          const blob = new Blob([data], { type: "application/zip" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", "expediente" + idExpediente + ".zip");

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.loading = false;
        })
        .catch(err => console.log(err))
    },
    cambiarVista() {
      this.vista = !this.vista;
      localStorage.setItem("vista", this.vista);
    },
    descargarExpedientes() {

    },
    redirectToExpediente(expediente) {
      this.$router.push({ path: '/modules/expediente/expediente/' + expediente.idExpediente });
    },
    crearExpediente() {
      const today = new Date();
      const formattedDate = today.toISOString().split('T')[0];
      let data = {
        "documentoAfiliado": "11111111",
        "fechaFormulacion": formattedDate,
        "cie": "",
        "codMipres": "",
        "bodega": {
          "bodegasId": {
            "idEmpresa": 1,
            "codigoBodega": this.bodegaId
          }
        },
        "tipoArchivo": "ATTACHMENT",
        "documentoMedico": "",
        "nombreArchivo": "sin-formula.pdf",
        "detalleProductos": []
      }
      this.$http
        .post("msa-process-file/api/expediente/crearExpediente", data)
        .then((result) => {
          if (result.data && result.data.nombrePagador) {
            this.ips = result.data.nombrePagador;
            this.estadoIps = true;
            this.ipsConfirmado = true;
          } else {
            this.ips = "";
            this.estadoIps = false;
          }
        });
    },
    handleStatusChange() {
      this.getExpedientes();
    },
    saveStatus() {
      this.dialog = true;

      let data = {
        estado: this.selectedStatus,
        motivoDevolucionExpediente: {
          'idMotivoDevolucion': this.selectedMotivoDevolucion
        },
        observacion: this.observation
      };
      console.log(data)

      this.$http
        .put(`msa-process-file/api/expediente/estadoExpediente/${this.expediente.idExpediente}`, data)
        .then((result) => {
          console.log(result)
          this.dialog = false;
          if (result.data.idExpediente) {
            Swal.fire({
              title: 'Expediente',
              text: 'El estado del expediente ha sido actualizado',
              icon: 'success',
              confirmButtonText: 'Aceptar'
            });

            // Actualizar el expediente con los datos completos de la respuesta
            this.expedientes = this.expedientes.map(exp => {
              if (exp.idExpediente === result.data.idExpediente) {
                return this.$set(this.expedientes, this.expedientes.indexOf(exp), result.data);
              }
              return exp;
            });

            this.selectedStatus = null;
            this.getExpedientes();
          } else {
            Swal.fire({
              title: 'Expediente',
              text: 'Hubo un error, intente nuevamente',
              icon: 'error',
              confirmButtonText: 'Aceptar'
            });
          }
        })
        .catch(error => {
          console.error("Error al actualizar estado:", error);
          Swal.fire({
            title: 'Error',
            text: 'No se pudo actualizar el estado del expediente',
            icon: 'error',
            confirmButtonText: 'Aceptar'
          });
        });
    }


  }
}
</script>

<style scoped>
.v-container {
  max-width: 900px;
}

.action-container {
  display: flex;
  align-items: center;
  /* Alinea verticalmente */
  gap: 8px;
  /* Espacio entre los elementos */
}

.custom-checkbox .v-input__control {
  border-radius: 50% !important;
  /* Checkbox redondo */
}

.v-btn {
  min-width: 36px;
  /* Tamaño más compacto */
  height: 36px;
}


.custom-checkbox .v-input__control {
  border-radius: 50% !important;
  /* Hace el checkbox redondo */
  box-shadow: none !important;
  /* Quita cualquier efecto de sombra */
  background-color: transparent !important;
  /* Quita el fondo gris */
  transition: background-color 0.3s ease;
}

.custom-checkbox:hover .v-input__control {
  background-color: rgba(0, 0, 255, 0.2) !important;
  /* Simula estar marcado */
}

.custom-checkbox .v-input__control::before {
  display: none !important;
  /* Elimina el hover de cuadro */
}

.folder-container {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
}

.folder-icon {
  width: 100%;
  height: auto;
  margin-right: 5px;
}

.folder-id {
  position: absolute;
  top: 50%;
  left: 37%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  color: black;
}

.custom-checkbox {
  border-radius: 60% !important;
  cursor: pointer;
  padding: 0 !important;
  margin: 0 !important
}

.hover-row {
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.hover-row:hover {
  background-color: #f5f5f5;
}

.bordered-row {
  border-bottom: 1px solid #dcdcdc;
  padding: 10px;
}

.faltantes-box {
  background-color: #e0e0e0;
  padding: 5px 10px;
  border-radius: 4px;
  font-weight: bold;
  color: #d32f2f;
}

.document-tag {
  margin-right: 8px;
}

.faltantes-box {
  background-color: #f0f0f0;
  border-radius: 4px;
  font-weight: bold;
  color: #f44336;
}

ul,
ol {
  list-style: none;
}

article.expedientes {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
}


article.expedientes>header {
  border-bottom: .1rem solid #ccc;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;
  position: inherit;
  background: white;
  -webkit-box-shadow: 2px 2px 5px -1px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 2px 2px 5px -1px rgba(0, 0, 0, 0.23);
  box-shadow: 2px 2px 5px -1px rgba(0, 0, 0, 0.23);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

article.expedientes>header h3 {
  padding-left: 1rem;
  margin-bottom: .5rem;
  padding-left: 1rem;
  margin-bottom: 1rem;
  text-align: left;
  color: #0D47A1;
  width: 100%;
}

article.expedientes>header>article {
  width: 96%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
}

article.expedientes>header>article>div {
  width: 100%;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  margin-bottom: 10px;
}

article.expedientes>header>article .calendar {
  position: relative;
}

article.expedientes>header>article .calendar figure {
  position: absolute;
  right: 10px;
  top: 7px;
}

article.expedientes>header>article>div>div {
  margin: 0;
  margin-right: 10px;
  width: 100%;
}

article.expedientes>header>article>div:first-child>div:first-child {
  margin-top: -1px;
}

article.expedientes>header>article div label {
  margin-left: .5rem;
  width: 100%;
}

article.expedientes>header>section {
  display: flex;
  justify-content: flex-end;
  margin: 5px 0 0 0;
  width: 96%;
}

article.expedientes>header>section button {
  margin-left: 10px;
}

article.expedientes i {
  margin-right: 0 !important;
}

article.expedientes>section {
  width: 100%;
  background: white;
}

article.expedientes>section ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: center;
  align-items: stretch;
  padding: 0
}

article.expedientes>section ul>li {
  /* aspect-ratio: 1 / 1; */
  /* background: white; */
  border-radius: 1rem;
  cursor: pointer;
  padding: 1%;
  margin: 2% 1%;
  width: 20%;
  min-width: 210px;
  position: relative;
  /* width: 260px;   */
  width: 180px;
}

article.expedientes>section ul>li .expediente-folder {
  position: absolute;
  width: 100%;
  z-index: 1;
  top: -55px;
  left: 0px;
}

article.expedientes>section ul>li .expediente-folder .v-image__image {
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  /* background-image: url("../assets/images/folder.JPG");  */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}


article.expedientes>section ul>li header {
  position: relative;
  z-index: 2;
}

article.expedientes>section ul>li header article h3 {
  font-size: 1.3rem;
  margin-bottom: .5rem;
  text-align: center;
}

article.expedientes>section ul>li header article h4 {
  font-weight: 400;
  font-size: .9rem;
  text-align: right;
  margin-top: -14px;
}

article.expedientes>section ul>li header article h5 {
  font-weight: 500;
  font-size: 11px;
  text-align: left;
  margin-top: 10px;
}

article.expedientes>section ul>li header article h5 span {
  font-weight: 800;
}

article.expedientes>section ul>li header aside {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 1rem;
}

article.expedientes>section ul>li header .expediente-id {
  position: absolute;
  width: 30px;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  border: 2px solid #0d47a1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  left: 0px;
  top: -10px;
  font-weight: bold;
  font-size: 13px;
}

article.expedientes>section ul>li header aside figure {
  background: #0d47a1;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 50px;
  width: 50px;
}

article.expedientes>section ul>li header aside figure i {
  color: white;
  font-size: 2rem;
}

article.expedientes>section ul>li div {
  margin-top: .5rem;
  z-index: 2;
  position: relative;
  padding: 0 !important;
}

article.expedientes>section ul>li div ol {
  padding: 0;
}

article.expedientes>section ul>li div ol li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-bottom: .5rem;
}

article.expedientes>section ul>li div ol li:last-child {
  margin-bottom: 0;
}

article.expedientes>section ul>li div ol li h5 {
  /* white-space: nowrap; */
  font-size: 10px;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 600;
}

article.expedientes>section ul>li div ol li figure {
  margin-right: .5rem;
  width: 30px;
  height: 22px;
}

article.expedientes>section ul>li div ol li figure i {
  border-radius: 50%;
  font-size: 1rem;
  padding: .2rem;
}

article.expedientes>section ul>li div ol li figure i {
  border: 1px solid #0D47A1;
  color: #0D47A1;
}

article.expedientes>section ul>li div ol li.incompleta figure i {
  border: 1px solid orange;
  color: orange;
}

article.expedientes>section ul>li div ol li.error1 figure i {
  border: 1px solid red;
  color: red;
}

article.expedientes>section ul>li>footer>div {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  height: 45px;
  align-items: center;
  margin-top: 10px;
  border-bottom: 1px solid #0D47A1;
}

article.expedientes>section ul>li>footer>div figure {
  width: 40px;
  display: flex;
  justify-content: center;
}

article.expedientes>section ul>li>footer>div figure button {
  margin: 0;
}

article.expedientes>section ul>li>footer>div figure i {}

article.expedientes>section ul>li>footer>div figure>* {
  margin: 0;
  padding: 0;
}

article.expedientes>section ul>li>footer>section {
  width: 100%;
  display: flex;
  border-top: 1px solid gray;
  padding-top: 10px;
  justify-content: center;
}

article.expedientes>section ul>li>footer>section p {
  font-size: 1rem;
  margin: 0;
  margin-left: 3px;
}

article.expedientes>section ul>li.completa header article h3 {
  color: #0d47a1;
}

article.expedientes>section ul>li.incompleta header article h3 {
  color: orange;
}

article.expedientes>section ul>li.incompleta header aside figure {
  background: orange;
}

article.expedientes>section ul>li.revision header article h3 {
  color: green;
}

article.expedientes>section ul>li.revision header aside figure {
  background: green;
}

article.expedientes>section ul>li.rechazada header article h3 {
  color: red;
}

article.expedientes>section ul>li.rechazada header aside figure {
  background: red;
}

article.expedientes .container .col-12 {
  padding: 3px;
  font-size: 15px;
}

.list-cell {
  flex: 1;
  text-align: left;
  padding: 5px;
  display: flex;
}

.list-cell.actions {
  display: flex;
}

.list-cell.actions button {
  margin-right: 10px;
}

.list-cell.actions .v-input--checkbox {
  margin-top: 0;
}

.list-cell.icon {
  flex: 0.3;
  /* Espacio reservado para el icono */
  display: flex;
  align-items: center;
  justify-content: center;
}

.list-cell.small {
  flex: 0.5;
  /* Más pequeño */
  font-size: 0.9rem;
}

.list-cell.large {
  flex: 2;
  /* Más ancho para nombres largos */
}

.list-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-weight: bold;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
}

.list-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-bottom: 1px solid #ddd;
  align-items: center;
  cursor: pointer;
}

.list-row:hover {
  background: #d9d9d9
}

.custom-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
}

.page-info {
  font-size: 16px;
  font-weight: bold;
}

.custom-pagination .pagination-select {
  width: 140px;
  max-width: 140px;
}

@media (min-width: 960px) {
  .container {
    max-width: initial;
  }
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  z-index: 2;
}

.loading h4 {
  width: 100%;
  text-align: center;
  color: white;
  font-size: 19px;
  margin-top: -20px;
  font-weight: 200;
}

@keyframes ldio-yzaezf3dcmj {
  0% {
    opacity: 1
  }

  100% {
    opacity: 0
  }
}

.ldio-yzaezf3dcmj div {
  left: 96px;
  top: 45px;
  position: absolute;
  animation: ldio-yzaezf3dcmj linear 1s infinite;
  background: #0d47a1;
  width: 8px;
  height: 18px;
  border-radius: 3.42px / 3.42px;
  transform-origin: 4px 55px;
}

.ldio-yzaezf3dcmj div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.9375s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(2) {
  transform: rotate(22.5deg);
  animation-delay: -0.875s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(3) {
  transform: rotate(45deg);
  animation-delay: -0.8125s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(4) {
  transform: rotate(67.5deg);
  animation-delay: -0.75s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(5) {
  transform: rotate(90deg);
  animation-delay: -0.6875s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(6) {
  transform: rotate(112.5deg);
  animation-delay: -0.625s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(7) {
  transform: rotate(135deg);
  animation-delay: -0.5625s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(8) {
  transform: rotate(157.5deg);
  animation-delay: -0.5s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(9) {
  transform: rotate(180deg);
  animation-delay: -0.4375s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(10) {
  transform: rotate(202.5deg);
  animation-delay: -0.375s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(11) {
  transform: rotate(225deg);
  animation-delay: -0.3125s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(12) {
  transform: rotate(247.5deg);
  animation-delay: -0.25s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(13) {
  transform: rotate(270deg);
  animation-delay: -0.1875s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(14) {
  transform: rotate(292.5deg);
  animation-delay: -0.125s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(15) {
  transform: rotate(315deg);
  animation-delay: -0.0625s;
  background: #0d47a1;
}

.ldio-yzaezf3dcmj div:nth-child(16) {
  transform: rotate(337.5deg);
  animation-delay: 0s;
  background: #0d47a1;
}

.loadingio-spinner-spinner-2by998twmg8 {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
}

.ldio-yzaezf3dcmj {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
  /* see note above */
}

.ldio-yzaezf3dcmj div {
  box-sizing: content-box;
}
</style>
<style>
article.expedientes .v-text-field.v-text-field--enclosed .v-text-field__details {
  display: none;
}
</style>